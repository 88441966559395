import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import InstarVisionCard01 from 'components/Software/Windows/InstarVision/Users/instarVisionCard01';
import InstarVisionCard02 from 'components/Software/Windows/InstarVision/Users/instarVisionCard02';
import UserTable from 'components/Software/Windows/InstarVision/Users/userTable';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Users/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "InstarVision offers you a highly customizable multi-level user management.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Users/' locationFR='/fr/Software/Windows/InstarVision/Users/' crumbLabel="Users" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c472633773feba3a574b1857742cf78c/29114/Banner_en-InstarVision2-0_Users.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAeElEQVQI143HMQ6CMBhA4f8qej4HNwzsDrRMNW6wyFVcXNUA8QQyUqCpDXkmMuhkeMk3PGHy4Cy4DvwAYzc/gaUZY9jFCQSHqFuPvg/oJqDrL1W/UJX/K6082WMi2Wui7Yb0ahHJn0jRIqVFTj/KpXrWhzOr4+Xzb+OtwPywBAkeAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c472633773feba3a574b1857742cf78c/e4706/Banner_en-InstarVision2-0_Users.avif 230w", "/en/static/c472633773feba3a574b1857742cf78c/d1af7/Banner_en-InstarVision2-0_Users.avif 460w", "/en/static/c472633773feba3a574b1857742cf78c/7f308/Banner_en-InstarVision2-0_Users.avif 920w", "/en/static/c472633773feba3a574b1857742cf78c/e1c99/Banner_en-InstarVision2-0_Users.avif 1380w", "/en/static/c472633773feba3a574b1857742cf78c/76ea5/Banner_en-InstarVision2-0_Users.avif 1840w", "/en/static/c472633773feba3a574b1857742cf78c/f696c/Banner_en-InstarVision2-0_Users.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c472633773feba3a574b1857742cf78c/a0b58/Banner_en-InstarVision2-0_Users.webp 230w", "/en/static/c472633773feba3a574b1857742cf78c/bc10c/Banner_en-InstarVision2-0_Users.webp 460w", "/en/static/c472633773feba3a574b1857742cf78c/966d8/Banner_en-InstarVision2-0_Users.webp 920w", "/en/static/c472633773feba3a574b1857742cf78c/445df/Banner_en-InstarVision2-0_Users.webp 1380w", "/en/static/c472633773feba3a574b1857742cf78c/78de1/Banner_en-InstarVision2-0_Users.webp 1840w", "/en/static/c472633773feba3a574b1857742cf78c/882b9/Banner_en-InstarVision2-0_Users.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c472633773feba3a574b1857742cf78c/81c8e/Banner_en-InstarVision2-0_Users.png 230w", "/en/static/c472633773feba3a574b1857742cf78c/08a84/Banner_en-InstarVision2-0_Users.png 460w", "/en/static/c472633773feba3a574b1857742cf78c/c0255/Banner_en-InstarVision2-0_Users.png 920w", "/en/static/c472633773feba3a574b1857742cf78c/b1001/Banner_en-InstarVision2-0_Users.png 1380w", "/en/static/c472633773feba3a574b1857742cf78c/161ec/Banner_en-InstarVision2-0_Users.png 1840w", "/en/static/c472633773feba3a574b1857742cf78c/29114/Banner_en-InstarVision2-0_Users.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c472633773feba3a574b1857742cf78c/c0255/Banner_en-InstarVision2-0_Users.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "users",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#users",
        "aria-label": "users permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Users`}</h2>
    <InstarVisionCard01 mdxType="InstarVisionCard01" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "default-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#default-user",
        "aria-label": "default user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Default User`}</h3>
    <p>{`When you start the software for the first time, the Default User is added and you are automatically logged in with them. Also, the `}<a parentName="p" {...{
        "href": "#activate-login"
      }}>{`Login`}</a>{` is initially disabled, so you do not have to log in to the software to access the user interface. The default user has the rights of an administrator and therefore no restrictions. If you have not changed the default user credentials, they will be:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <UserTable mdxType="UserTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`We strongly recommend that you `}<a parentName="p" {...{
        "href": "#edit-a-user"
      }}>{`change`}</a>{` the default password as soon as the `}<a parentName="p" {...{
        "href": "#activate-login"
      }}>{`Login`}</a>{` is active.`}</p>
    <h3 {...{
      "id": "activate-login",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#activate-login",
        "aria-label": "activate login permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Activate Login`}</h3>
    <p>{`The Login is disabled by default. Go to the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/System#SoftwareLogin"
      }}>{`System Menu - Software Login`}</a>{` to activate the Login. You will need to restart the software after customizing / enabling the administrator account and signing in with your new credentials. Please make sure that you keep your administrator login in a safe place. You can no longer access the software if the login is lost. When you sign in with a limited user account, a login prompt appears every time you try to access a locked area. Then simply enter your administrator login to increase your user rights.`}</p>
    <h3 {...{
      "id": "add-a-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-user",
        "aria-label": "add a user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a user`}</h3>
    <p>{`To add a new user, click in the `}<strong parentName="p">{`User`}</strong>{` input field and replace the displayed name with the user name of the new user to be added. Then click in the input field `}<strong parentName="p">{`Password`}</strong>{` and replace it with the password of the new user. For security reasons, the password is protected with asterisks from prying eyes for entering or displaying. As next, click the `}<strong parentName="p">{`+`}</strong>{` button to add the new entry to the list of users. The new user is now selected in the list and you can set its `}<a parentName="p" {...{
        "href": "#user-rights"
      }}>{`User Rights`}</a>{`. Please note that at this time the data is not yet saved and you can not log in with the new user. To do this, press the `}<strong parentName="p">{`Save`}</strong>{` button.`}</p>
    <h3 {...{
      "id": "edit-a-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#edit-a-user",
        "aria-label": "edit a user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Edit a user`}</h3>
    <p>{`If, for example, you would like to change the password of an existing user, simply select them with a click of them at the list of users. The user is then marked and updated accordingly in the input fields `}<strong parentName="p">{`User`}</strong>{` and `}<strong parentName="p">{`Password`}</strong>{`. The password is always displayed with asterisks. Replace it with the new password and confirm with a click on the `}<strong parentName="p">{`Save`}</strong>{` button. In the same way, you can also edit the username. You can also reassign all `}<a parentName="p" {...{
        "href": "#user-rights"
      }}>{`User Rights`}</a>{` by checking the checkboxs after selecting the user. Please do not forget to save the changes at the end by a click on the `}<strong parentName="p">{`Save`}</strong>{` button.`}</p>
    <h3 {...{
      "id": "delete-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#delete-user",
        "aria-label": "delete user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Delete user`}</h3>
    <p>{`If you want to delete a user, just select them by clicking them in the list of users. Then click the `}<strong parentName="p">{`Delete`}</strong>{` button to remove the selected user from the list. For that the user is no longer available for future logins, you need to click on the `}<strong parentName="p">{`Save`}</strong>{` button.`}</p>
    <h3 {...{
      "id": "user-rights",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#user-rights",
        "aria-label": "user rights permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`User Rights`}</h3>
    <p>{`Adding or removing check marks below the user list expands or restricts access to specific areas.
On the left side all available channels are listed. Once you uncheck one of these channels, the selected user will no longer be able to see the video stream of that channel. On the right side are the User Rights to expand or hide settings. There are the following User Rights:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Administrator`}</strong>{`: If this checkbox is set, the selected user has no restrictions. He can use the views and functions of the software in a full extent, even if further user rights are ticked off. Please make sure that newly added users with limited user rights will always have this checkmark removed. In addition, this right ensures that the user list and its rights can be displayed and edited in the `}<strong parentName="li">{`Users`}</strong>{` tab. Otherwise a user without this right can there only  change his personal password.`}</li>
      <li parentName="ul"><strong parentName="li">{`Camera List`}</strong>{`: If this checkbox is set, the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Camera_List"
        }}>{`Camera List`}</a>{` tab will be displayed. Uncheck it if you do not want that the selected user is able to add, edit and delete cameras.`}</li>
      <li parentName="ul"><strong parentName="li">{`Camera PTZ`}</strong>{`: If this checkbox is set, the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/PTZ"
        }}>{`Camera PTZ`}</a>{` tab is displayed. Uncheck it if you do not want that the selected user is able to pan, tilt and zoom controllable cameras.`}</li>
      <li parentName="ul"><strong parentName="li">{`Image Adjust`}</strong>{`: If this checkbox is set, the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Image_Adjust"
        }}>{`Image Adjust`}</a>{` tab will be displayed. Uncheck it if you do not want that the selected user is able to change image settings such as brightness, contrast, and saturation for the cameras.`}</li>
      <li parentName="ul"><strong parentName="li">{`Webserver`}</strong>{`: If this checkbox is set, the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Webserver"
        }}>{`Webserver`}</a>{` tab will be displayed. Uncheck it if you do not want that the selected user is able to modify the settings of InstarVision's own web server, such as the local web address.`}</li>
      <li parentName="ul"><strong parentName="li">{`Record`}</strong>{`: If this checkbox is set, the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Record"
        }}>{`Record`}</a>{` tab will be displayed. Uncheck it if you do not want that the selected user is able to create   Continuous or Alarm Recordings, or take a series of photos.`}</li>
      <li parentName="ul"><strong parentName="li">{`System`}</strong>{`: If this checkbox is set, the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/System"
        }}>{`System`}</a>{`tab will be displayed. Uncheck it if you do not want that the selected user is able to change the language or layout which will be displayed when starting InstarVision.`}</li>
      <li parentName="ul"><strong parentName="li">{`Log Info`}</strong>{`: If this checkbox is set, the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Advanced/Log"
        }}>{`Log Info`}</a>{` button is available in the menu of the `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Advanced"
        }}>{`Advanced`}</a>{` tab. Uncheck it if you do not want that the selected user is able to view InstarVision log information.`}</li>
      <li parentName="ul"><strong parentName="li">{`Remote`}</strong>{`: If this checkbox is set, remote video streams can be received. Uncheck if you do not want that the selected user is able to access a video stream of InstarVision's own web server without sign in or to access a channel's RTSP stream (see `}<a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/System#RTSPServerPort"
        }}>{`System Menu - RTSP Server Port`}</a>{`).`}</li>
    </ul>
    <h2 {...{
      "id": "side-navigation---users",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---users",
        "aria-label": "side navigation   users permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Users`}</h2>
    <InstarVisionCard02 mdxType="InstarVisionCard02" />
    <EuiSpacer mdxType="EuiSpacer" />
    <UserTable mdxType="UserTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <br />
    <h3 {...{
      "id": "activate-the-password-protection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#activate-the-password-protection",
        "aria-label": "activate the password protection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Activate the Password Protection`}</h3>
    <p>{`The Password Protection is deactivated by default. You will have to restart the software after customizing/activating the Administrator account and log in with your new credentials. Please make sure to write down your administrator log-in in a secure place. You will no longer be able to access the software if the login is lost. When you login with an account with limited user rights, a login prompt will appear every time you try to access an area that is locked. Just type in your Administrator login to elevate your user rights.`}</p>
    <h3 {...{
      "id": "remote-access",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#remote-access",
        "aria-label": "remote access permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Remote Access`}</h3>
    <p>{`Adding or removing check marks below the user list, will add or remove access to those cameras or menus from the user interface for the selected user. The Remote Checkbox defines whether a user is allowed to access the software´s `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Webserver/"
      }}>{`Webserver`}</a>{` and receive the live RTSP streams (see `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/System/"
      }}>{`RTSP Server Port`}</a>{`) from your cameras. In case no user is added to the software, you will still be able to access the webserver - but please be aware, that certain plugins (Quicktime) require a login to be able to stream videos.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      